<template>
  <div style="width:100%;padding: 0 30px;" novalidate="true">
    <!-- radio + reference temperature -->
    <div class="row d-flex g-0 mt-1">

      <div class="col d-flex g-0" style="flex-grow: 1;">
        <div class="col d-flex flex-column" style="flex-grow: 1;">
          <span>
            Curve model
          </span>
          <div class="mt-1">
            <input class="form-control" style="width:70%;" readonly :value="curveLabels[curveType]" />
          </div>
        </div>
        <!-- Curve equation -->
        <div class="col d-flex align-items-center justify-content-center">
          <div>
            <img class="equation-img" v-if="curveType === 'exponential'" src="../../../assets/equations/curve_equation_expo.png" />
            <img class="equation-img" v-else src="../../../assets/equations/curve_equation_log.png" />
          </div>
        </div>
      </div>

      <div class="col d-flex g-0" style="flex-grow: 3;margin-left: 60px;">
        <!-- Computation mode and ASTM -->
        <div class="col d-flex" style="flex-shrink: 1;">

          <div class="col d-flex flex-column" style="flex-shrink: 1;">
            <span>
              Computation model
            </span>
            <div class="mt-2">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="computation-model" id="computation-model-arrhenius"
                  value="arrhenius" :checked="modelName === 'arrhenius'" @click="changeModel('arrhenius')">
                <label class="form-check-label" for="computation-model-arrhenius">
                  Arrhenius
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="computation-model" id="computation-model-nursesaul"
                  value="nurse_saul" :checked="modelName === 'nurse_saul'" @click="changeModel('nurse_saul')">
                <label class="form-check-label" for="computation-model-nursesaul">
                  Nurse-Saul
                </label>
              </div>
            </div>
          </div>

          <div class="col d-flex flex-column" style="flex-shrink: 1;">
            <span>
              Reference temperature
            </span>
            <div class="mt-1 d-flex align-items-center">
              <input class="form-control" style="width:50%;" type="number" v-model.number="referenceTemperature" min="10"
                max="30" step="0.5" @change="callTimerForUpdate()" />
              <div class="ms-1"> °C </div>
            </div>
          </div>
        </div>


        <!-- Maturity / Teq equation -->
        <div class="col d-flex justify-content-start align-items-center" style="flex-grow: 1;">
          <img style="height: 46px; width: auto;" v-if="modelName === 'arrhenius'" src="../../../assets/equations/teq_arrhenius.png" />
          <img style="height: 46px; width: auto;" v-else src="../../../assets/equations/maturity_ns.png" />
        </div>
      </div>



      <!-- Hidden for now, could be used later -->
      <!--
        <div class="col-2  d-flex align-items-center ">
          <label for="astm">ASTM: &nbsp; </label>
          <input type="checkbox" id="astm" v-model="astm">
        </div>
        -->

    </div>

    <!-- parameters zone -->
    <div class="adjustment-parameters-block justify-content-between mt-3">
      <div class="d-flex justify-content-around flex-grow-1">
        <div v-for="param, key of curveParameters[curveType]" :key="key" class="d-flex">
          <div class="col">

            <!-- label -->
            <div class="row g-0">
              <div v-html="getParameterLabel(param)"></div>
              <!-- Boundary bubble & Panel -->
              <div style="position:relative;">
                <div class="boundary-bubble" :class="getBoundaryStatus(param)" @mouseenter="showInfoPanel(param)"
                  @mouseleave="hideInfoPanel(param)">
                  <div style="position: relative;top: -4px;">
                    <font-awesome-icon icon="fa-solid fa-circle-info" />
                  </div>
                </div>
                <div :id="'box-' + param" class="info-panel" :class="getBoundaryStatus(param)" style="z-index: -99;">
                  <template v-for="item, index of getInfoTexts(param)" :key="index">
                    <p v-html="item"></p>
                  </template>
                </div>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <!-- Input -->
              <div class="input-group">
                <input type="number" class="form-control" style="height:38px;" :id="'adjustment-' + param"
                  :class="[isModified(param) ? 'modified' : '', getBoundaryStatus(param)]"
                  :style="{ width: hasUnit(param) ? '130px' : '186px' }"
                  :value="parametersFacade[modelName][curveType][param]" :step="parameterAttributes[param].step"
                  :min="parameterAttributes[param].min" :max="parameterAttributes[param].max" :data-property="param"
                  data-step=0.1 @focus="onFocusIn" @blur="onFocusOut" @change="handleInput">
                <div v-if="hasUnit(param)" class="input-group-append">
                  <span class="input-group-text">{{ parameterAttributes[param].unit }}</span>
                </div>
              </div>

              <div class="d-flex flex-row">
                <!-- lock button -->
                <LockButton :isLocked="lockedParameters.includes(param)" @click="toggleLock(param)"></LockButton>

                <!-- reset button -->
                <ResetButtonSingle :isModified="isModified(param)" @click=" resetField(param)"></ResetButtonSingle>
              </div>

            </div>
          </div>

        </div>
      </div>

      <!-- Refresh All button -->
      <div class="mt-4 d-flex justify-content-center" style="width:230px; ">
        <button type="button" class="refresh-all btn btn-outline-warning" @click=" resetFields()">
          <span>Reset all fields</span>
          <font-awesome-icon style="margin-left:5px;" icon="fa-solid fa-rotate-left" />
        </button>
      </div>
    </div>

  </div>

  <div class="row g-0 mt-2">
    <div class="d-flex mt-2 justify-content-around" style="position:relative">
      <div class="d-flex">
        <div id="graph1" style="width:45%;">
        </div>
        <div id="graph2" style="width:45%;">
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-center mt-4">
    <button type="button" class="btn btn-outline-primary me-4" style="border-radius: 2px!important;"
      @click="generateReport">
      Download PDF Report
    </button>

  </div>
</template>

<script>
import * as common from '@/scripts/common';
import ResetButtonSingle from './ResetButtonSingle.vue'
import LockButton from './LockButton.vue'

import Plotly from 'plotly.js-dist-min';
import axios from 'axios';
import { nextTick } from 'vue';

export default {
  name: 'AdjustmentTab',
  components: {
    ResetButtonSingle,
    LockButton,
  },
  props: {
    modelName: String,
    lastCalibrationPayload: Object,
    isAstm: Boolean,
    curveType: String,
    initialLockedParameters: Object,
    overwriteParams: Object,
    lastCalibrationResponse: Object,
    lastAdjustmentResponse: Object,
  },
  emits: [
    'lockedParametersChanged',
    'changeValue',
    'changeCurve',
    'changeModel',
    'removeParam',
    'receivedResponse',
    'requestSuccessToast',
    'startWaiting',
    'stopWaiting',
  ],
  created() {
    this.parameterAttributes = common.parameterAttributes;
  },
  async mounted() {
    this.lockedParameters = this.initialLockedParameters;
    let data = this.lastCalibrationResponse[this.modelName][this.curveType];
    if (this.lastAdjustmentResponse[this.modelName][this.curveType]) {
      data = this.lastAdjustmentResponse[this.modelName][this.curveType];
    }
    // setup facade
    this.parametersFacade[this.modelName][this.curveType] = {}
    for (let key of this.curveParameters[this.curveType]) {
      const decimals = this.parameterAttributes[key].decimals;
      this.parametersFacade[this.modelName][this.curveType][key] = parseFloat(this.getDisplayedValue(key).toFixed(decimals));
    }
    this.referenceTemperature = this.getDisplayedValue("T_ref");

    this.buildGraph(data);
  },
  data() {
    return {
      parameterAttributes: {},
      referenceTemperature: 20,
      curveParameters: {
        exponential: ["ult_strength", "beta", "tau", "a_Ea"],
        logarithmic: ["a_log", "b_log", "ns_datum"],
      },
      mutableData: {
        arrhenius: {
          exponential: {
            ult_strength: null,
            tau: null,
            beta: null,
            a_Ea: null,
          },
        },
        nurse_saul: {
          logarithmic: {
            a_log: null,
            b_log: null,
            ns_datum: null,
          },
        },
        overwriteParams: {}
      },
      parametersFacade: {
        arrhenius: {
          exponential: {
            ult_strength: null,
            tau: null,
            beta: null,
            a_Ea: null,
          },
        },
        nurse_saul: {
          logarithmic: {
            a_log: null,
            b_log: null,
            ns_datum: null,
          },
        },
      },
      response: null,
      hoveringInfoBubble: null,
      curveLabels: {
        "exponential": "Exponential",
        "logarithmic": "Logarithmic"
      },
      lockedParameters: [],
      lastFocusedParam: null,
      // used to prevent multiple successive lambda calls
      updateTimeoutID: undefined,
    };
  },
  computed: {
    rSquared() {
      return this.lastCalibrationResponse[this.modelName][this.curveType].parameters.r_squared;
    }
  },
  watch: {
    async modelName(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.callAdjustmentLambda();
      }
    }

  },
  methods: {
    showInfoPanel(param) {
      document.getElementById(`box-${param}`).style.zIndex = 99
    },
    hideInfoPanel(param) {
      document.getElementById(`box-${param}`).style.zIndex = -99
    },

    hasUnit(key) {
      return this.parameterAttributes[key].unit !== '';
    },
    isModified(key) {
      let overwriteValue = this.overwriteParams[this.modelName][this.curveType][key];
      return (overwriteValue !== null && overwriteValue !== undefined);
    },

    // get relevant value (from overwrite param if is modified, lastAdjustment if was modified since last calibration,
    // else from lastCalibration if hasn't been adjusted)
    getDisplayedValue(key) {
      const isModified = this.isModified(key);
      const isLocked = this.lockedParameters.includes(key);
      let displayedValue = null
      // modified by user
      if (isModified) {
        // locked
        if (isLocked) {
          displayedValue = this.overwriteParams[this.modelName][this.curveType][key];
        }
        // not locked, already made adjustment
        else if (this.lastAdjustmentResponse[this.modelName][this.curveType] !== null) {
          displayedValue = this.lastAdjustmentResponse[this.modelName][this.curveType]['parameters'][key];
          // not locked, no adjustment yet
        } else {
          displayedValue = this.overwriteParams[this.modelName][this.curveType][key];
        }
        // not modified
      } else {
        // same behavior wether it is locked or not (display adjustment response or calib if hasn't been done yet)
        //  already made adjustment
        if (this.lastAdjustmentResponse[this.modelName][this.curveType] !== null) {
          displayedValue = this.lastAdjustmentResponse[this.modelName][this.curveType]['parameters'][key];
          //  no adjustment yet
        } else {
          displayedValue = this.lastCalibrationResponse[this.modelName][this.curveType]['parameters'][key];
        }
      }

      return displayedValue
    },

    // return string containing css classes name depending on parameter compared to typical boundaries
    getBoundaryStatus(key) {
      let classes = ''
      let value = this.getDisplayedValue(key);
      if (value < this.parameterAttributes[key].min || value > this.parameterAttributes[key].max) {
        return 'forbidden';
      }
      if (value < this.parameterAttributes[key].innerMin || value > this.parameterAttributes[key].innerMax) {
        classes += 'warning';
      }
      if ((key === "ns_datum" || key === "a_Ea") && this.lastCalibrationPayload[this.modelName][this.curveType].logs.length < 2) {
        classes += ' one-log';
      }
      return classes;
    },

    // return string containing information, depending on the classes determined by getBoundaryStatus() method
    getInfoTexts(key) {
      let texts = [this.parameterAttributes[key].description];
      let label = this.parameterAttributes[key].label;
      let status = this.getBoundaryStatus(key);
      let value = this.getDisplayedValue(key);
      if (status === 'forbidden') {
        texts.push(`Results to be taken with caution. Value for ${label} [${value}] is out of typical range [${this.parameterAttributes[key].min}, ${this.parameterAttributes[key].max}].`);
      }
      if (status.includes('warning')) {        
        texts.push(`Results to be taken with caution. Value for ${label} [${value}] is out of typical range [${this.parameterAttributes[key].innerMin}, ${this.parameterAttributes[key].innerMax}].`);
      }
      if (status.includes('one-log')) {
        texts.push("Only one calibration data was provided; default value was received.");
      }
      return texts;
    },

    getParameterLabel(key) {
      return this.parameterAttributes[key].label;
    },

    toggleLock(parameter) {
      // if we find an index for parameter in lockedParameters array, it mean it was locked => unlock by removing said index
      const index = this.lockedParameters.indexOf(parameter);
      if (index !== -1) {
        this.lockedParameters.splice(index, 1);
      }
      // else, it means it wasn't locked: add it to lockedParameters
      else {
        this.lockedParameters.push(parameter);
      }
    },

    handleChangeLock() {
      this.$emit('lockedParametersChanged', this.lockedParameters);
    },

    // call resetField method for all parameters of current model + curve,
    // then call for adjustment lambda to recompute values
    resetFields() {
      for (const key in this.mutableData[this.modelName][this.curveType]) {
        this.resetField(key, true)
      }
      this.lockedParameters = [];
      this.callAdjustmentLambda();
    },


    // reset given parameter of current model + curve, 
    // then call lambda to recompute (except if isAllReset argument is true, meaning it will be called once all reset are made)
    resetField(parameter, isAllReset = false) {
      this.mutableData[this.modelName][this.curveType][parameter] = this.lastCalibrationResponse[this.modelName][this.curveType].parameters[parameter];
      if (this.lockedParameters.includes(parameter)) {
        this.toggleLock(parameter)
      }
      this.$emit('removeParam', parameter);
      if (isAllReset == false) {
        this.callAdjustmentLambda();
      }
    },

    // notify parent of curve type change, then call lambda for computation
    changeCurve(curveType) {
      this.$emit('changeCurve', curveType);
      this.callAdjustmentLambda();
    },

    // notify parent of model type change, then call lambda for computation
    changeModel(modelName) {
      this.$emit('changeModel', modelName);
    },

    // notify parent of a change in a parameter
    emitChangeToParent(parameter) {
      const newValue = this.mutableData[this.modelName][this.curveType][parameter]
      this.$emit('changeValue', parameter, newValue);
    },

    // triggered when user focuses on a parameter input
    // changes value in input to actual value with maximum decimals number
    onFocusIn(event) {
      const el = event.target;
      const property = el.dataset.property;
      this.lastFocusedParam = property;
      el.value = this.getDisplayedValue(property);
    },

    // triggered when user focus leaves a parameter input
    // change value in input to facade value with reduced decimal number
    onFocusOut(event) {
      this.lastFocusedParam = null;
      const el = event.target;
      const property = el.dataset.property;
      el.value = this.parametersFacade[this.modelName][this.curveType][property];
    },

    // triggered when user changes value of a parameter
    // sets said parameter's value of facade object to rounded value
    // sets parameter's value of actual object to input value
    // then emit change to parent and call for recomputation
    handleInput(event) {
      const el = event.target;
      const property = el.dataset.property;
      let newValue = parseFloat(el.value);

      // reset input value to previous one if new value is invalid (empty or not a number)
      if (isNaN(newValue)) {
        el.value = this.getDisplayedValue(property);
        return;
      }
      // enforce input value inside 'forbidden' boundaries
      const min = this.parameterAttributes[property].min;
      const max = this.parameterAttributes[property].max;

      newValue = newValue < min ? min : newValue;
      newValue = newValue > max ? max : newValue;

      if (!this.lockedParameters.includes(property)) {
        this.lockedParameters.push(property)
      }
      

      this.parametersFacade[this.modelName][this.curveType][property] = parseFloat(newValue.toFixed(this.parameterAttributes[property].decimals));  // round facade property to number of digits

      if (el.value % 1 !== 0) { // only if value is decimal, re-set to real value since user may be still inputing
        this.$nextTick(() => {
          el.value = this.getDisplayedValue(property);   // manually set HTML element value to unrounded since user focus is still on it (potentially with more inputs coming)
        });
      }
      this.mutableData[this.modelName][this.curveType][property] = newValue;
      this.changeInputValue(property);
    },

    changeInputValue(key) {
      this.emitChangeToParent(key);
      this.callTimerForUpdate();
    },

    // prevent successive lambda calls
    callTimerForUpdate() {
      if (typeof this.updateTimeoutID === 'number') {
        clearTimeout(this.updateTimeoutID);
      }
      this.updateTimeoutID = setTimeout(async () => await this.callAdjustmentLambda(), 250);
    },

    async submit() {
      await this.callAdjustmentLambda();
    },

    async callAdjustmentLambda() {
      const functionParameters = {};
      const maturityParameters = {};
      for (let key of this.lockedParameters) {
        // Arrhenius
        if (this.modelName === "arrhenius") {
          if (key === "a_Ea") {
            maturityParameters[key] = this.getDisplayedValue(key);
          }
          if (["ult_strength", "beta", "tau"].includes(key)) {
            functionParameters[key] = this.getDisplayedValue(key);
          }
          // Nurse Saul
        } else {
          if (key === "ns_datum") {
            maturityParameters[key] = this.getDisplayedValue(key);
          }
          if (["a_log", "b_log"].includes(key)) {
            functionParameters[key] = this.getDisplayedValue(key);
          }
        }
      }

      // builds payload wth logs saved from last calibration on first tab, modified parameters, and model and function type
      let payload = {
        logs: this.lastCalibrationPayload[this.modelName][this.curveType].logs,
        parameters: {
          maturity_parameters: { ...maturityParameters },
          function_parameters: { ...functionParameters },
          T_ref: this.referenceTemperature
        },
        model: {
          usage: "calibration",
          model_name: this.modelName,
          function_type: this.curveType
        }
      }
      // remove empty parameter objects
      if (Object.keys(payload.parameters.maturity_parameters).length == 0) {
        delete payload.parameters.maturity_parameters;
      }
      if (Object.keys(payload.parameters.function_parameters).length == 0) {
        delete payload.parameters.function_parameters;
      }

      this.$emit("startWaiting");
      try {
        let resp = await axios.post(this.$modelLambdaUrl, payload);
        if (!resp) {
          throw new Error('Server is not responding.');
        }

        this.response = resp.data.body.results;
        this.buildGraph(this.response);

        this.$emit('receivedResponse', payload, this.response);
        this.mutableData[this.modelName][this.curveType] = this.response.parameters

        this.$emit('requestSuccessToast', 'Adjustment done!')
      } catch (error) {
        this.$toast.error(error, { position: "top", duration: 2000 });
      }
      this.$emit("stopWaiting");
      // re-focus on previous input field
      if (this.lastFocusedParam) {
        const id = `adjustment-${this.lastFocusedParam}`;
        nextTick(() => {
          document.getElementById(id).focus();
        })
      }
    },

    async buildGraph(calibrationData) {
      if (!calibrationData) {
        return
      }
      let { calib_curve, ...rest } = calibrationData.calibration_curves_teq;
      let combinedCurve = calib_curve.mat_strength.reduce((stack, current) => {
        return [[...stack[0], current[0]], [...stack[1], current[1]]]
      }, [[], []]);

      let teqData = [{
        x: combinedCurve[0],
        y: combinedCurve[1],
        mode: 'lines', line: { color: 'rgb(0, 0, 0)', width: 2 },
        name: `Calibration curve`,
        legendgroup: 'combined',
      }]
      let i = 0;
      Object.keys(rest).forEach((item) => {
        let curve = rest[item].mat_strength.reduce((stack, current) => {
          return [[...stack[0], current[0]], [...stack[1], current[1]]];
        }, [[], []])
        teqData.push({
          x: curve[0],
          y: curve[1],
          mode: 'markers', marker: { color: common.colors[i] },
          name: `Dataset ${i + 1}: measured`,
          legendgroup: 'markers',
        });
        i += 1;
      })

      const maxTime = teqData[0].x[teqData[0].x.length - 1];
      const maxCurve = combinedCurve[1].reduce((stack, current) => {
        return (current > stack) ? current : stack;
      }, 0);
      common.adjustmentLayout1.xaxis.range = [0, maxTime + common.getGraphStep(maxTime)]
      common.adjustmentLayout1.yaxis.range = [0, maxCurve + common.getGraphStep(maxCurve)]

      // remove old graph legend
      const legendNode = document.querySelector(".graph-rsquared-legend");
      if (legendNode) {
        legendNode.remove();
      }


      Plotly.newPlot("graph1", {
        data: teqData,
        layout: common.adjustmentLayout1,
        config: { responsive: true }
      });

      this.createGraphLegend("#graph1");

      common.adjustmentLayout1.xaxis.title.text = (this.modelName === 'arrhenius') ? 'Teq (h)' : 'Maturity (°C/h)';

      let curvesTimeData = []
      i = 0;
      Object.keys(calibrationData.calibration_curves_time).forEach(item => {
        // scatter
        let scatters = calibrationData.calibration_curves_time[item].time_strength.reduce((stack, current) => {
          return [[...stack[0], current[0]], [...stack[1], current[1]]];
        }, [[], []])
        curvesTimeData.push({
          x: scatters[0],
          y: scatters[1],
          mode: 'markers', marker: { color: common.colors[i] },
          name: `Dataset ${i + 1}: measured`,
          legendgroup: `combined ${i}`,
        })
        // predicted curve
        let predicted = calibrationData.calibration_curves_time[item].predicted_strength.reduce((stack, current) => {
          return [[...stack[0], current[0]], [...stack[1], current[1]]];
        }, [[], []])
        curvesTimeData.push({
          x: predicted[0],
          y: predicted[1],
          mode: 'lines', line: { color: common.colors[i], width: 2 },
          name: `Dataset ${i + 1}: computed`,
          legendgroup: `combined ${i}`,
        })
        i += 1;
      });

      let maxStrength = 0;
      for (let data of curvesTimeData) {
        for (let log of data.y) {
          if (log > maxStrength) {
            maxStrength = log;
          }
        }
      }

      common.adjustmentLayout2.yaxis.range = [0, maxStrength + common.getGraphStep(maxStrength)]

      Plotly.newPlot("graph2", {
        data: curvesTimeData,
        layout: common.adjustmentLayout2,
        config: { responsive: true }
      });
    },

    createGraphLegend(graphId) {
      const newSpan = document.createElement("span");
      newSpan.textContent = "r² = " + this.rSquared.toFixed(3) 
      newSpan.classList.add("graph-rsquared-legend")
      const parentElement = document.querySelector(`${graphId} .user-select-none `)
      parentElement.appendChild(newSpan)
    },

    async generateReport() {
      let lastResponse = this.lastAdjustmentResponse[this.modelName][this.curveType];
      if (!lastResponse) {
        lastResponse = this.lastCalibrationResponse[this.modelName][this.curveType];
      }

      // lastResponse.calibration_curves_teq.calib_curve["mat_strength"] = lastResponse.calibration_curves_teq.calib_curve.mat_strength;
      const logs = this.lastCalibrationPayload[this.modelName][this.curveType].logs;

      const body = {
        project_name: "project_name",
        concrete_name: "concrete_name",
        logs,
        ...lastResponse
      }
      body.parameters["r_squared"] = body.parameters.fit_indicator

      // lambda call
      this.$emit("startWaiting");
      try {
        const payload = {
          body,
          template_name: `smartcast_${this.modelName}`,
        }
        const result = await axios.post(this.$pdfLambdaUrl, payload);

        if (!result) {
          throw new Error('no result received on create PDF');
        }
        if (result.data.error_messages.length > 0) {
          const errorString = result.data.error_messages.join("; \n")
          throw new Error(errorString);
        }
        const base64pdfData = result.data.body.results.pdfreport;

        // create download link and emulate click
        const link = document.createElement('a');
        link.download = 'calibration_report.pdf';
        link.href = 'data:application/octet-stream;base64,' + base64pdfData;

        link.click();

      } catch (error) {
        console.log(error);
        this.$toast.error(error, { position: "top", duration: 2000 });
      }
      this.$emit("stopWaiting");
    }
  },
}
</script>

<style>
.adjustment-parameters-block {
  display: flex;
  align-items: center;
  background-color: #F5F6FA;
  height: 92px;
}

.equation-img {
    height: 100%;
    width: auto;
}


.btn-warning {
  color: white !important;
}

.refresh-all {
  border-radius: 2px !important;
  font-weight: bold !important;
}

.btn-outline-warning:hover {
  color: white !important;
}


.labeled-input-container>span:first-of-type {
  text-align: end;
}

.labeled-input-container>span {
  min-width: 40px;
  margin-right: 5px;
}

.labeled-input-container>input {
  width: 160px;
  margin-right: 5px;
  border-color: black;
}

.modified {
  border-color: rgb(90, 132, 223) !important;
}

.modified.form-control:focus {
  border-color: #1A4370 !important;
  box-shadow: 0 0 0 0.2rem rgba(90, 132, 223, 0.45) !important;
}

.warning {
  border-color: #EC6608 !important;
}

.forbidden {
  border-color: #E30613 !important;
}

.warning.form-control:focus {
  border-color: #EC6608 !important;
  box-shadow: 0 0 0 0.2rem rgba(255, 160, 52, 0.45) !important;
}

.labeled-input-container>.btn {
  padding: 4px 8px;
}

.boundary-bubble {
  position: absolute;
  right: 28px;
  top: -24px;
  font-size: 1em;
  border-radius: 50%;
  height: 18px;
  /* color is interior of the bubble, border and background-color is 'i' character and circle */
  color: #F5F6FA;
  background-color: grey;
  border: 1px grey solid;

  opacity: 0.8;
}

.boundary-bubble.warning,
.boundary-bubble.one-log {
  color: #EC6608;
  background-color: #F5F6FA;
  border: 1px #F5F6FA solid;
  opacity: 1.0;
}

.boundary-bubble.forbidden {
  color: #E30613;
  background-color: #F5F6FA;
  border: 1px #F5F6FA solid;
  opacity: 1.0;
}

.graph-rsquared-legend {
  padding: 6px;
  border: dashed black 1px;
  background-color: white;
  position: relative;
  top: 104px;
  left: 110px;
  width: fit-content;
  z-index: 1;
}
</style>
