<template>
  <span>Add new reading:</span>
  <form @submit.prevent="addReading()" class="row g-0 d-flex justify-content-around input-line mt-1">
    <div class="input-group" style="width: calc(50% - 32px);">
      <input type="number" ref="timeInput" min=0 step="any" class="form-control" 
      :tabindex="1 + ((type === 'temperature') * 100) + (inputIndex * 2000)" v-model="timeInput">
      <div class="input-group-append">
        <div class="input-group-text square">h</div>
      </div>
    </div>

    <div class="input-group" style="width: calc(50% - 32px);">
      <input type="number" min="0" step="any" class="form-control" 
      :tabindex="2 + ((type === 'temperature') * 100) + (inputIndex * 2000)" v-model="valueInput">
      <div class="input-group-append">
        <div v-if="type === 'strength'" class="input-group-text square mpa-label" id="basic-addon2">MPa</div>
        <div v-else class="input-group-text square mpa-label" id="basic-addon2" style="padding-left: 10px!important;">°C</div>
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-center" style="width: 32px">
      <button type="submit" :tabindex="3 + ((type === 'temperature') * 100) + (inputIndex * 2000)" class="align-self-center btn btn-secondary btn-sm" style="color:white!important;"
        :disabled="hasInvalidInput">
        <font-awesome-icon icon="fa-solid fa-plus" />
      </button>
    </div>
  </form>

  <br>
  <div class="row g-0 d-flex justify-content-center mb-1">
    <div style="width: calc(50%);text-align: center;"><b>Time (h)</b></div>
    <div style="width: calc(50%);text-align: center;"><b style="margin-left: 8px; margin-right: 44px;">{{ type === "strength" ? "Strength (MPa)" : "Temperature (°C)" }}</b></div>
  </div>
  <div v-for="item, index of initialData" :key="index" class="row g-0 d-flex justify-content-around input-line">
    <div class="input-group" style="width: calc(50% - 32px);">
      <input type="number" :id="'range-input-time' + type + '-' + index" min=0 step=0.1 class="form-control"
        :tabindex="getTabIndex(index) + 1" :value="isNaN(item[0]) ? null : item[0]"
        :disabled="item[0] === null && index < 3" @blur="onTimeInputBlur($event)" @change="onTimeChanged($event, index)"
        :data-index="index">
      <div class="input-group-append">
        <div class="input-group-text square">h</div>
      </div>
    </div>
    <div class="input-group" style="width: calc(50% - 32px);">
      <input type="number" :id="'range-input-value' + type + '-' + index" min="0" step=0.1 class="form-control"
        :tabindex="getTabIndex(index) + 2" @keydown.up.prevent="" @keydown.down.prevent=""
        @change="onValueChanged($event, index)" :value="isNaN(item[1]) ? null : item[1]"
        :disabled="item[0] === null">
      <div class="input-group-append ">
        <div v-if="type === 'strength'" class="input-group-text square mpa-label" id="basic-addon2">MPa</div>
        <div v-else class="input-group-text square mpa-label" id="basic-addon2" style="padding-left: 10px!important;">°C</div>
      </div>
    </div>
    <div class="d-flex justify-content-center" style="width:32px;">
      <button type="button" class="align-self-center btn btn-danger btn-sm" style="border-radius:  2px!important;" @click="deleteReading(index)"
        :disabled="initialData.length <= minimumReadingNumber">
        <font-awesome-icon icon="fa-solid fa-xmark" />
      </button>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue';

export default {
  name: 'RangeInput',
  props: {
    type: String, // 'strength' or 'temperature'
    initialData: Object,
    inputIndex: Number,
  },
  emits: [
    'deleteRequested',
    'readingAdded',
    'readingChanged',
  ],
  data() {
    return {
      timeInput: null,
      valueInput: null,
      newTime: null, // store new time value of currently changed time
    }
  },
  computed: {
    minimumReadingNumber() {
      return this.inputIndex === 0 ? 3 : 1;
    },
    hasInvalidInput() {
      return (isNaN(parseFloat(this.timeInput)) || isNaN(parseFloat(this.valueInput)));
    },
  },
  methods: {
    addReading() {
      const newReading = [this.timeInput, this.valueInput];
      this.timeInput = null;
      this.valueInput = null;
      this.$emit("readingAdded", newReading);
      this.$refs.timeInput.focus();
    },
    getTabIndex(rowIndex) {
      let tabIndex = (rowIndex + 1) * 10 + this.inputIndex * 2000;
      if (this.type === 'temperature') {
        tabIndex += 100;
      }
      return tabIndex;
    },
    // triggered when time input element loses focus
    onTimeInputBlur(event) {
      const oldIndex = parseInt(event.target.dataset.index);
      let newIndex = 0;
      if (!this.newTime) {
        newIndex = oldIndex;
      } else {
        for (let i = 0; i < this.initialData.length; i++) {
          if (this.initialData[i][0] === this.newTime) {
            newIndex = i;
            break;
          }
        }
      }
      this.newTime = null;
      if (newIndex !== oldIndex) {
        // change focus only if user wants to add value after time (tabulation or click on value field of the reading)
        if (event.relatedTarget) {
          if (event.relatedTarget.id === `range-input-value${this.type}-${oldIndex}`) {
            // get element with new id(after sort), focus on it
            const el = document.getElementById(`range-input-value${this.type}-${newIndex}`);
            el.focus()
          }
        }
      }
    },

    onTimeChanged(event, index) {
      const newTime = parseFloat(event.target.value);
      this.newTime = newTime;

      this.$emit("readingChanged", index, [newTime, this.initialData[index][1]]);
      nextTick(() => {
        let newIndex = 0;
        for (let i = 0; i < this.initialData.length; i++) {
          if (this.initialData[i][0] === this.newTime) {
            newIndex = i;
            break;
          }
        }
        const el = document.getElementById(`range-input-time${this.type}-${newIndex}`);
        el.focus()
      })
    },

    onValueChanged(event, index) {
      const newValue = parseFloat(event.target.value);
      this.$emit("readingChanged", index, [this.initialData[index][0], newValue]);
    },

    deleteReading(index) {
      this.$emit("deleteRequested", index);
    },
  }

}
</script>