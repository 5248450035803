<template>
  <div class="thin-border-corner my-2">

    <div class="row g-0">
      <div class="d-flex input-head justify-content-between align-items-center" @click="requestCollapse">
        <button type="button" :id="'collapse-predi-input-btn-' + index" class="button-no-decoration" data-bs-toggle="collapse"
          :data-bs-target="'#collapse-predi-' + index">
          <span class="input-head-text">Temperature scenario {{ index + 1 }}</span>
        </button>

        <!--  Delete component button -->
        <div class="d-flex justify-content-center align-items-center">
          <button type="button" class="btn btn-danger" :class="isSingleParam ? ' disabled' : ''" style="border-radius: 2px!important;" :disabled="isSingleParam"
            @click="$emit('requestDelete', index)">
            <font-awesome-icon icon="fa-solid fa-xmark" />
          </button>
        </div>
        <!-- End Delete button -->
      </div>
    </div>


    <!-- Collapsible wrapper-->
    <div :id="'collapse-predi-' + index" class="input-content collapse">
      <div class="row g-0 mt-2" style="margin: 30px;">

        <div class="col">
          <!-- Choice radios-->
          <div class="mt-3" style="width:100%;position: relative;">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" :name="'predi-' + index + '-temp-type'" :id="'predi-' + index + '-temp-type-fixed'" value="fixed"
                v-model="mutableData.selectedTemperatureType" @click="setTemperatureInputType('fixed')">
              <label class="form-check-label" :for="'predi-' + index + '-temp-type-fixed'">
                Fixed temperature
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" :name="'predi-' + index + '-temp-type'" :id="'predi-' + index + '-temp-type-range'" value="range"
                v-model="mutableData.selectedTemperatureType" @click="setTemperatureInputType('range')">
              <label class="form-check-label" :for="'predi-' + index + '-temp-type-range'">
                Manual values range
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" :name="'predi-' + index + '-temp-type'" :id="'predi-' + index + '-temp-type-file'" value="file"
                v-model="mutableData.selectedTemperatureType" @click="setTemperatureInputType('file')">
              <label class="form-check-label" :for="'predi-' + index + '-temp-type-file'">
                Import from file
              </label>
            </div>
            <!-- Temperature Info Bubble: file-->
            <div v-if="mutableData.selectedTemperatureType === 'file'" style="position: absolute;z-index:10;top: -4px;left: 500px;">
              <div class="info-bubble self-align-center active"
                @mouseenter="hoveringTempFormatBubble = true" 
                @mouseleave="hoveringTempFormatBubble = false"
              >
                <font-awesome-icon icon="fa-solid fa-circle-info" />
              </div>
              <div v-if="hoveringTempFormatBubble" class="info-panel">
                - File must be .xlsx or .txt <br>
                - Column A: time (h) <br>
                - Column B: temperature (°C) <br>
                - First row must be valid values or column headers <br>
                <img src="../../../assets/log_sample.png" style="border: black 1px solid;" />
              </div>
            </div>
          </div>
          <!-- Inputs-->
          <div class="d-flex flex-column mt-3">
            <!-- Fixed-->
            <template v-if="mutableData.selectedTemperatureType == 'fixed'">
              <div class="mt-2" style="width:300px;">
                <div class="g-0 input-line">

                  <div class="input-group">
                    <input class="form-control" type="number" min=-10 max=60 step=0.5
                      v-model="mutableData.temperatureInputs['fixed'][0][1]"
                      @input="changeTemperature($event)" />
                    <div class="input-group-append">
                      <div class="input-group-text square" id="basic-addon2">°C</div>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <!-- Range -->
            <template v-if="mutableData.selectedTemperatureType == 'range'">
              <RangeInput 
                :initialData="temperatureData" :type="'temperature'" :inputIndex="index"
                @readingAdded="addTemperature" @readingChanged="onTemperatureReadingChanged" @deleteRequested="deleteTemperature">
              </RangeInput>
            </template>

            <!-- File -->
            <template v-if="mutableData.selectedTemperatureType === 'file'">
              <FileInput :usage="'prediction'" :property="'temperature'"
                :dataIndex="mutableData.id" :fieldIndex="index" :fileName="mutableData.temperatureFileName"
                @fileChanged="temperatureFileChanged"
              ></FileInput>
            </template>

          </div>
          <!-- End Temperature input -->
        </div>

        <!-- Graph -->
        <div class="col">
          <div :id="'predi-temperature-' + index">
          </div>
        </div>

      </div>
    </div>
    <!-- End Collapsible wrapper-->

  </div>
</template>

<script>
import FileInput from '@/components/Elements/FileInput';
import RangeInput from '@/components/Elements/RangeInput';
import * as fileReader from '@/scripts/fileReader';
import * as common from '@/scripts/common';

import Plotly from 'plotly.js-dist-min';


export default {
  name: 'PredictionInput',
  components: {
    FileInput,
    RangeInput,
  },
  props: {
    index: null,
    initialData: Object,
    isSingleParam: Boolean,
  },
  emits: [
    'requestDelete',
    'changeValue',
  ],
  mounted() {
    this.mutableData = JSON.parse(JSON.stringify(this.initialData));
    this.buildGraph();
  },
  data() {
    return {
      hoveringTempFormatBubble: false,
      mutableData: {
        id: 0,
        temperatureInputs: {
          'fixed': [[0.0, 20.0], [672.0, 20.0]],
          'range': [[0.0, 20.0], [4.0, 40.0], [12.0, 40.0], [18.0, 20.0], [72.0, 20.0]],
          'file': [],
        },
        temperatureFileName: '',
        selectedTemperatureType: 'fixed',
      }
    }
  },
  computed: {
    temperatureData() {
      return this.mutableData.temperatureInputs[this.mutableData.selectedTemperatureType];
    },
  },
  methods: {
    requestCollapse() {
      const el = document.getElementById(`collapse-predi-input-btn-${this.index}`);
      el.click();
    },

    setTemperatureInputType(type) {
      if (type === this.mutableData.selectedTemperatureType) {
        return;
      }
      this.mutableData.selectedTemperatureType = type;
      this.onValueChange();
    },

    addTemperature(reading) {
      // search in array if we find reading [null, null]
      let firstNullReadingIndex = -1;
      for (let i = 0; i < this.temperatureData.length; i++) {
        if (Number.isNaN(parseFloat(this.temperatureData[i][0])) && Number.isNaN(parseFloat(this.temperatureData[i][1]))) {
          firstNullReadingIndex = i;
          break;
        }
      }
      if (firstNullReadingIndex === -1) {
        this.mutableData.temperatureInputs[this.mutableData.selectedTemperatureType].push(reading);
      } else {
        this.mutableData.temperatureInputs[this.mutableData.selectedTemperatureType][firstNullReadingIndex] = reading;
      }
      this.mutableData.temperatureInputs[this.mutableData.selectedTemperatureType] = common.sortByTime(this.temperatureData);
      this.handleTemperatureDataChange();
    },

    onTemperatureReadingChanged(index, reading) {
      this.mutableData.temperatureInputs[this.mutableData.selectedTemperatureType][index] = reading;
      this.handleTemperatureDataChange()
    },

    deleteTemperature(index) {
      this.mutableData.temperatureInputs[this.mutableData.selectedTemperatureType].splice(index, 1);
      this.handleTemperatureDataChange()
    },

    handleTemperatureDataChange(){
      this.mutableData.temperatureInputs[this.mutableData.selectedTemperatureType] = common.sortByTime(this.temperatureData);
      this.onValueChange();
    },

    changeTemperature(event) {
      const temperatureType = this.mutableData.selectedTemperatureType;
      let newValue = (event.target.value === '') ? 0.0 : Math.min(Math.max(parseFloat(event.target.value), -10), 60);
      this.mutableData.temperatureInputs[temperatureType][0][1] = parseFloat(newValue.toFixed(1));
      if (temperatureType === 'fixed') {
        this.mutableData.temperatureInputs[temperatureType][1][1] = parseFloat(newValue.toFixed(1));
      }
      this.onValueChange();
    },

    async temperatureFileChanged(event) {
      const inputElement = document.getElementById(`prediction-temperature-${this.mutableData.id}-${this.index}-file-input`);
      this.mutableData.temperatureInputs['file'] = [];
      if (!(event.target.files && event.target.files[0])) {
        this.mutableData.temperatureFileName = '';
        inputElement.value = '';
        return
      }
      const extension = event.target.value.split('.').pop();
      let content = null;
      try {
        switch (extension) {
          case 'xlsx':
            content = await fileReader.parseXLSX(event.target.files[0]);
            fileReader.checkFileData(content); // will throw error if content has invalid data or is too short
            break;
          case 'txt':
            content = await fileReader.parseTXT(event.target.files[0]);
            fileReader.checkFileData(content); // will throw error if content has invalid data or is too short
            break;
          default:
            this.mutableData.temperatureFileName = '';
            inputElement.value = '';
            this.$toast.error('Error! Supports only xlsx files', { position: "top", duration: 4000 });
            return;
        }
      } catch (error) {
        this.mutableData.temperatureFileName = '';
        inputElement.value = '';
        this.mutableData.temperatureInputs['file'] = [];
        this.$toast.error(`Error while parsing file: ${error}`, { position: "top", duration: 4000 });
        this.onValueChange();
        return
      }
      this.mutableData.temperatureFileName = event.target.value.split('\\').pop();
      this.mutableData.temperatureInputs['file'] = content;
      inputElement.value = '';
      this.onValueChange();
    },

    onValueChange() {
      this.buildGraph();
      this.$emit('changeValue', this.index, this.mutableData);
    },

    buildGraph() {
      let x = [];
      let y = [];
      for (let item of this.temperatureData) {
        x.push(item[0]);
        y.push(item[1]);
      }

      const maxTemperature = y.reduce((stack, current) => {
        return (current > stack) ? current : stack;
      }, 0);
      if (maxTemperature) {
        common.predictionLayout1.yaxis.range = [0, maxTemperature + common.getGraphStep(maxTemperature)]
      }

      Plotly.newPlot(`predi-temperature-${this.index}`,
        [{
          x,
          y,
          mode: 'lines',
          line: {
            color: common.colors[this.index], width: 2, dash: 'dash'
          },
          name: `Scenario ${this.index + 1}`,
          text: x.reduce((stack, current) => { current; return [...stack, `Scenario ${this.index + 1}`] }, []),
          hoverinfo: "x+y+text",
        }],
        common.predictionLayout1,
        { responsive: true }
      );
    },
  },
}

</script>
