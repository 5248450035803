<template>
  <div class="thin-border-corner my-4">
    <!-- title-row -->
    <div class="row g-0">
      <div class="d-flex input-head justify-content-between align-items-center" @click="requestCollapse">
        <button type="button" :id="'collapse-calib-input-btn-' + index" class="button-no-decoration" data-bs-toggle="collapse" :data-bs-target="'#collapse-calib-' + index">
          <span class="input-head-text">Calibration Data {{ index + 1 }}</span>
        </button>
        <!--  Delete component button -->
        <div class="d-flex justify-content-center align-items-center">
          <button type="button" class="btn btn-danger" :class="isSingleParam ? ' disabled' : '' " :disabled="isSingleParam" style="border-radius: 2px!important;" @click="$emit('requestDelete', index)">
            <font-awesome-icon icon="fa-solid fa-xmark" />
          </button>
        </div>
        <!-- End Delete button -->
      </div>

    </div>

    <!-- Collapsible wrapper-->
    <div :id="'collapse-calib-' + index" class="collapse input-content">
      <div class="row g-0 d-flex justify-content-between" style="margin: 30px;">
        <!-- Strength -->
        <div class="d-flex flex-column align-items-center" style="width:45%;">
          <div class="d-flex" style="width:100%;position: relative;">
            <span class="input-title">
              Compressive strength
            </span>
            <!-- Strength Info Bubble: file-->
            <div v-if="mutableData.selectedStrengthType === 'file'" style="position: absolute;top: -4px;left: 150px;">
              <div class="info-bubble self-align-center active"
                @mouseenter="hoveringStrengthFormatBubble = true" 
                @mouseleave="hoveringStrengthFormatBubble = false"
              >
                <font-awesome-icon icon="fa-solid fa-circle-info" />
              </div>
              <div v-if="hoveringStrengthFormatBubble" class="info-panel">
                - File must be .xlsx or .txt <br>
                - Column A: time (h) <br>
                - Column B: strength (MPa) <br>
                - First row must be valid values or column headers <br>
                <img src="../../../assets/log_sample.png" style="border: black 1px solid;" />
              </div>
            </div>
          </div>
          <!-- Input type button-->
          <div class="mt-3" style="width:100%;">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" :name="'calib-' + index +'-strength-type'" :id="'calib-' + index + '-strength-type-range'" value="range" v-model="mutableData.selectedStrengthType"  @click="setStrengthInputType('range')">
              <label class="form-check-label" :for="'calib-' + index + '-strength-type-range'">
                Manual input
              </label>
            </div>
            <div class="form-check form-check-inline ms-3">
              <input class="form-check-input" type="radio" :name="'calib-' + index +'-strength-type'" :id="'calib-' + index + '-strength-type-file'" value="file" v-model="mutableData.selectedStrengthType" @click="setStrengthInputType('file')">
              <label class="form-check-label" :for="'calib-' + index + '-strength-type-file'">
                Import from file
              </label>
            </div>

          </div>
          <!-- Inputs -->
          <div class="mt-3" style="width:100%;">

            <!-- File -->
            <template v-if="mutableData.selectedStrengthType === 'file'">
              <FileInput :usage="'calibration'" :property="'strength'" 
              :dataIndex="mutableData.id" :fieldIndex="index"
              :fileName="mutableData.strengthFileName"
              @fileChanged="strengthFileChanged"
              ></FileInput>
            </template>

            <!-- Range -->
            <template v-else>
              <RangeInput 
                :initialData="strengthData" :type="'strength'" :inputIndex="index"
                @readingAdded="addStrength" @readingChanged="onStrengthReadingChanged" @deleteRequested="deleteStrength">
              </RangeInput>
            </template>
          </div>
        </div>

        <!-- Temperature -->
        <div class="d-flex flex-column align-items-center" style="width:45%;">
          <div class="d-flex" style="width:100%;position: relative;">
            <span class="input-title">
              Curing Temperature
            </span>
            <!-- Temperature Info Bubble: range-->
            <div v-if="mutableData.selectedTemperatureType === 'range'" style="position: absolute;top: -4px;left: 150px;">
              <div class="info-bubble self-align-center" :class="isTempTimeUnderStrengthTime  ? 'warning': ''"
                @mouseenter="hoveringInfoBubble = isTempTimeUnderStrengthTime" 
                @mouseleave="hoveringInfoBubble = false"
              >
                <font-awesome-icon icon="fa-solid fa-circle-exclamation" />
              </div>
              <div v-if="hoveringInfoBubble" class="info-panel warning">
                Your temperature input stops earlier than strength's. Be aware that we will extrapolate temperature
                to match strength time range ({{ lastStrengthTime }}h).
              </div>
            </div>
            <!-- Temperature Info Bubble: file-->
            <div v-if="mutableData.selectedTemperatureType === 'file'" style="position: absolute;top: -4px;left: 150px;">
              <div class="info-bubble self-align-center active"
                @mouseenter="hoveringTempFormatBubble = true" 
                @mouseleave="hoveringTempFormatBubble = false"
              >
                <font-awesome-icon icon="fa-solid fa-circle-info" />
              </div>
              <div v-if="hoveringTempFormatBubble" class="info-panel">
                - File must be .xlsx or .txt <br>
                - Column A: time (h) <br>
                - Column B: temperature (°C) <br>
                - First row must be valid values or column headers <br>
                <img src="../../../assets/log_sample.png" style="border: black 1px solid;" />
              </div>
            </div>
          </div>
          <!-- Choice radios-->
          <div class="mt-3" style="width:100%;">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" :name="'calib-' + index + '-temp-type'" :id="'calib-' + index + '-temp-type-fixed'" value="fixed" v-model="mutableData.selectedTemperatureType"  @click="setTemperatureInputType('fixed')">
              <label class="form-check-label" :for="'calib-' + index + '-temp-type-fixed'">
                Fixed temperature
              </label>
            </div>
            <div class="form-check form-check-inline ms-3">
              <input class="form-check-input" type="radio" :name="'calib-' + index + '-temp-type'" :id="'calib-' + index + '-temp-type-range'" value="range" v-model="mutableData.selectedTemperatureType" @click="setTemperatureInputType('range')">
              <label class="form-check-label" :for="'calib-' + index + '-temp-type-range'">
                Manual values range
              </label>
            </div>
            <div class="form-check form-check-inline ms-3">
              <input class="form-check-input" type="radio" :name="'calib-' + index + '-temp-type'" :id="'calib-' + index + '-temp-type-file'" value="file" v-model="mutableData.selectedTemperatureType" @click="setTemperatureInputType('file')">
              <label class="form-check-label" :for="'calib-' + index + '-temp-type-file'">
                Import from file
              </label>
            </div>
          </div>
          <!-- Inputs -->
          <div class="mt-3" style="width:100%;">

            <!-- Fixed-->
            <template v-if="mutableData.selectedTemperatureType == 'fixed'">
              <div class="" style="width:300px;">

                  <div class="g-0 input-line">

                    <div class="input-group">
                      <input class="form-control" type="number" min=-10 max=60 step=0.5
                      v-model="mutableData.temperatureInputs['fixed'][0][1]"
                      @input="changeTemperature($event, 0)" />
                      <div class="input-group-append">
                        <div class="input-group-text square" id="basic-addon2">°C</div>
                      </div>
                    </div>
                  </div>
              </div>
            </template>

            <!-- Range -->
            <template v-if="mutableData.selectedTemperatureType == 'range'">
              <RangeInput 
                :initialData="temperatureData" :type="'temperature'" :inputIndex="index"
                @readingAdded="addTemperature" @readingChanged="onTemperatureReadingChanged" @deleteRequested="deleteTemperature">
              </RangeInput>
            </template>


            <!-- File -->
            <template v-if="mutableData.selectedTemperatureType == 'file'">
              <FileInput :usage="'calibration'" :property="'temperature'"
                :dataIndex="mutableData.id" :fieldIndex="index" :fileName="mutableData.temperatureFileName"
                @fileChanged="temperatureFileChanged"
              ></FileInput>
            </template>

          </div>

        </div>

      </div>
      <!-- End Inputs -->

      <!-- Graph -->
      <div class="row g-0 mt-2" style="margin-bottom: 45px;">
        <div class="d-flex mt-2" style="position:relative">

          <div class="d-flex justify-content-between">
            <div style="width:50%;">
              <div :id="'graph1-' + index">
              </div>
            </div>

            <div style="width:50%;">
              <div :id="'graph2-' + index">
              </div>
            </div>

          </div>

        </div>
      </div>
      <!-- End Graph -->
    </div>
    <!-- End Collaspible wrapper-->

  </div>
</template>

<script>
import FileInput from '@/components/Elements/FileInput';
import RangeInput from '@/components/Elements/RangeInput';
import * as fileReader from '@/scripts/fileReader';
import * as common from '@/scripts/common';
import Plotly from 'plotly.js-dist-min';

export default {
  name: 'ParametersInput',
  components: {
    FileInput,
    RangeInput,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    initialData: Object,
    isSingleParam: Boolean,
  },
  emits: [
    'requestDelete',
    'changeValue',
  ],
  mounted() {
    this.isMounting = true
    let { selectedStrengthType, selectedTemperatureType, ...rest } = JSON.parse(JSON.stringify(this.initialData));
    this.mutableData.selectedStrengthType = selectedStrengthType;
    this.mutableData.selectedTemperatureType = selectedTemperatureType;
    this.mutableData = Object.assign(this.mutableData, rest)
    setTimeout(() => {
      this.buildGraph();
      this.isMounting = false;
    }, 10);
  },
  data() {
    return {
      isMounting: true,
      //those three hovering variables are used to display or not an info panel
      hoveringInfoBubble: false, // used for warning messages
      hoveringStrengthFormatBubble: false,  // used for strength file upload instructions
      hoveringTempFormatBubble: false,  // used for temperature file upload instructions
      // holds data resulting from user inputs 
      mutableData: {
        id: 0,
        strengthInputs: {
          //'range': [[16, 2.0], [24, 6.0], [48, 15.0], [72, 20.0], [168, 25.0], [672, 32.0]],
          'range': [[null,null], [null,null], [null,null]], 
          'file': [],
        },
        selectedStrengthType: 'range',
        strengthFileName: '',
        temperatureInputs: {
          'fixed': [[0.0, 20.0], [48.0, 20.0]],
          'range': [[0.0, 20.0], [4.0, 40.0], [12.0, 40.0], [18.0, 20.0], [72.0, 20.0]],
          'file': [],
        },
        temperatureFileName: '',
        selectedTemperatureType: 'fixed',
      },
      sortCount: 0,
    }
  },
  computed: {
    // returns a boolean to decide if warning that strength time exceeds temperature time needs to be displayed
    isTempTimeUnderStrengthTime() {
      // not necessary if no inputs
      if (this.strengthData.length === 0 || this.temperatureData.length === 0){
        return false;
      }
      // not necessary if temperature is constant or read from file
      if (this.mutableData.selectedTemperatureType !== 'range') {
        return false
      }
      let lastStrengthInput = this.strengthData[this.strengthData.length - 1];
      let lastTemperatureInput = this.temperatureData[this.temperatureData.length - 1];
      return lastStrengthInput[0] > lastTemperatureInput[0];
    },
    // shorthand for time value of last strength reading
    lastStrengthTime() {
      return this.strengthData[this.strengthData.length - 1][0]
    },
    // shorthand for relevant strength data (depending on current input type)
    strengthData() {
      return this.mutableData.strengthInputs[this.mutableData.selectedStrengthType];
    },
    // shorthand for relevant temperature data (depending on current input type)
    temperatureData() {
      return this.mutableData.temperatureInputs[this.mutableData.selectedTemperatureType];
    },
  },
  methods: {
    // used to emulate click on input header, leading to trigger collapsing
    requestCollapse() {
      const el = document.getElementById(`collapse-calib-input-btn-${this.index}`);
      el.click();
    },

    setStrengthInputType(type){
      if (this.mutableData.selectedStrengthType === type) {
        return;
      }
      this.mutableData.selectedStrengthType = type;
      this.onValueChange()
    },
    
    setTemperatureInputType(type){
      if (this.mutableData.selectedTemperatureType === type) {
        return;
      }
      this.mutableData.selectedTemperatureType = type;
      this.onValueChange();
    },
    
    addStrength(reading) {
      // search in array if we find reading [null, null]
      let firstNullReadingIndex = -1;
      for (let i = 0; i < this.strengthData.length; i++) {
        if (Number.isNaN(parseFloat(this.strengthData[i][0])) && Number.isNaN(parseFloat(this.strengthData[i][1]))) {
          firstNullReadingIndex = i;
          break;
        }
      }
      if (firstNullReadingIndex === -1) {
        this.mutableData.strengthInputs[this.mutableData.selectedStrengthType].push(reading);
      } else {
        this.mutableData.strengthInputs[this.mutableData.selectedStrengthType][firstNullReadingIndex] = reading;
      }
      this.mutableData.strengthInputs[this.mutableData.selectedStrengthType] = common.sortByTime(this.strengthData);
      this.handleStrengthDataChange();
    },

    onStrengthReadingChanged(index, reading) {
      this.mutableData.strengthInputs[this.mutableData.selectedStrengthType][index] = reading;
      this.handleStrengthDataChange()
    },

    deleteStrength(index) {
      this.mutableData.strengthInputs[this.mutableData.selectedStrengthType].splice(index, 1);
      this.handleStrengthDataChange()
    },

    handleStrengthDataChange(){
      this.mutableData.strengthInputs[this.mutableData.selectedStrengthType] = common.sortByTime(this.strengthData);
      
      let isInvalid = false
      for (let item of this.strengthData) {
        if (Number.isNaN(parseFloat(item[0])) || Number.isNaN(parseFloat(item[1])) ) {
          isInvalid = true;
          break;
        }
      }
      if (isInvalid === false) {
        this.setLastTemperatureTime()
      }
      this.onValueChange();
    },


    addTemperature(reading) {
      // search in array if we find reading [null, null]
      let firstNullReadingIndex = -1;
      for (let i = 0; i < this.temperatureData.length; i++) {
        if (Number.isNaN(parseFloat(this.temperatureData[i][0])) && Number.isNaN(parseFloat(this.temperatureData[i][1]))) {
          firstNullReadingIndex = i;
          break;
        }
      }
      if (firstNullReadingIndex === -1) {
        this.mutableData.temperatureInputs[this.mutableData.selectedTemperatureType].push(reading);
      } else {
        this.mutableData.temperatureInputs[this.mutableData.selectedTemperatureType][firstNullReadingIndex] = reading;
      }
      this.mutableData.temperatureInputs[this.mutableData.selectedTemperatureType] = common.sortByTime(this.temperatureData);
      this.handleTemperatureDataChange();
    },

    onTemperatureReadingChanged(index, reading) {
      this.mutableData.temperatureInputs[this.mutableData.selectedTemperatureType][index] = reading;
      this.handleTemperatureDataChange()
    },

    deleteTemperature(index) {
      this.mutableData.temperatureInputs[this.mutableData.selectedTemperatureType].splice(index, 1);
      this.handleTemperatureDataChange()
    },

    handleTemperatureDataChange(){
      this.mutableData.temperatureInputs[this.mutableData.selectedTemperatureType] = common.sortByTime(this.temperatureData);
      this.onValueChange();
    },

    // method called when changing fixed temperature
    changeTemperature(event, i) {
      //const temperatureType = this.mutableData.selectedTemperatureType;
      let newValue = (event.target.value === '') ? 0.0 : Math.min(Math.max(parseFloat(event.target.value), -10), 60);
      this.temperatureData[i][1] = parseFloat(newValue.toFixed(1));
      if (this.mutableData.selectedTemperatureType === 'fixed') {
        this.temperatureData[1][1] = parseFloat(newValue.toFixed(1));
      }
      this.onValueChange();
    },
    

    addManualTemperatureInput() {
      let lastData = this.temperatureData[this.temperatureData.length - 1];
      let newData = [null, null];
      if (lastData[0]) {
        newData[0] = lastData[0] + 1.0
      }
      if (lastData[1]) {
        newData[1] = lastData[1]
      }
      this.mutableData.temperatureInputs[this.mutableData.selectedTemperatureType].push(newData);
      this.onValueChange();
    },

    // triggered when strength time values are changed, or added/deleted a reading
    // if temperature type is 'fixed', ensures  temperature max time is at most equal to strength max time
    setLastTemperatureTime(){
      if(this.mutableData.selectedTemperatureType !== "fixed"){
        return;
      }
      if (isNaN(parseFloat(this.strengthData[this.strengthData.length -1][0]))) {
        return;
      }
      const lastStrengthTime = this.strengthData[this.strengthData.length -1][0]
      this.temperatureData[1][0] = lastStrengthTime
    },

    async strengthFileChanged(event) {
      const inputElement = document.getElementById(`calibration-strength-${this.mutableData.id}-${this.index}-file-input`);
      this.mutableData.strengthInputs['file'] = [];

      if (!(event.target.files && event.target.files[0])) {
        this.mutableData.strengthFileName = '';
        inputElement.value = '';
        return;
      }
      const extension = event.target.value.split('.').pop();
      let content = null;
      try {
        switch (extension) {
          case 'xlsx':
            content = await fileReader.parseXLSX(event.target.files[0]);
            fileReader.checkFileData(content, "strength"); // will throw error if content has invalid data or is too short
            this.mutableData.strengthInputs['file'] = content;
            break;
          case 'txt':
            content = await fileReader.parseTXT(event.target.files[0]);
            fileReader.checkFileData(content, "strength"); // will throw error if content has invalid data or is too short
            this.mutableData.strengthInputs['file'] = content;
            break;
          default:
            this.mutableData.strengthFileName = '';
            inputElement.value = ''
            this.$toast.error('Error! Supports only xlsx files', { position: "top", duration: 4000 });
            return;
        }
      } catch (error) {
        this.mutableData.strengthFileName = '';
        inputElement.value = '';
        this.mutableData.strengthInputs['file'] = [];
        this.$toast.error(`Error while parsing file: ${error}`, { position: "top", duration: 4000 });
        this.onValueChange();
        return;
      }
      this.mutableData.strengthFileName = event.target.value.split('\\').pop();
      inputElement.value = '';
      this.setLastTemperatureTime()
      this.onValueChange();
    },

    async temperatureFileChanged(event) {
      const inputElement = document.getElementById(`calibration-temperature-${this.mutableData.id}-${this.index}-file-input`);
      this.mutableData.temperatureInputs['file'] = []
      if (!(event.target.files && event.target.files[0])) {
        this.temperatureFileName = '';
        inputElement.value = '';
        return
      }
      const extension = event.target.value.split('.').pop();
      let content = null;
      try {
        switch (extension) {
          case 'xlsx':
            content = await fileReader.parseXLSX(event.target.files[0]);
            fileReader.checkFileData(content); // will throw error if content has invalid data or is too short
            break;
          case 'txt':
            content = await fileReader.parseTXT(event.target.files[0]);
            fileReader.checkFileData(content); // will throw error if content has invalid data or is too short
            break;
          default:
            this.mutableData.temperatureFileName = '';
            inputElement.value = '';
            this.$toast.error('Error! Supports only xlsx files', { position: "top", duration: 4000 });
            return;
        }
      } catch (error) {
        this.mutableData.temperatureFileName = '';
        inputElement.value = '';
        this.mutableData.temperatureInputs['file'] = [];
        this.$toast.error(`Error while parsing file: ${error}`, { position: "top", duration: 4000 });
        this.onValueChange();
        return
      }

      this.mutableData.temperatureInputs['file'] = content;
      if (this.mutableData.temperatureInputs['file'][0][0] > 0.0) {
        this.mutableData.temperatureInputs['file'].unshift([0.0, this.mutableData.temperatureInputs['file'][0][1]]);
      }
      this.mutableData.temperatureFileName = event.target.value.split('\\').pop();
      inputElement.value = '';
      this.onValueChange();
    },

    onValueChange() {
      this.buildGraph();
      this.$emit('changeValue', this.index, this.mutableData);
    },

    buildGraph() {
      // Concrete graph
      let str = this.strengthData.reduce((stack, current) => {
        return [[...stack[0], current[0]], [...stack[1], current[1]]];
      }, [[], []]);

      let strPlot = [{
        x: str[0],
        y: str[1],
        mode: 'lines+markers', line: { color: common.colors[this.index], width: 2 },
        name: 'Concrete strength',
        text: 'Concrete strength',
        hoverinfo: "x+y+text",
      }];

      // get max
      let maxTime = str[0][str[0].length -1];
      const maxStrength = str[1].reduce((stack, current) => {
        return (current > stack) ? current : stack;
      }, 0);
      if (maxTime || maxStrength) {
        common.calibrationLayout1.xaxis.range = [0, maxTime + common.getGraphStep(maxTime)];
        common.calibrationLayout1.yaxis.range = [0, maxStrength + common.getGraphStep(maxStrength)];
      }

      Plotly.newPlot(`graph1-${this.index}`,
        strPlot,
        common.calibrationLayout1,
        { responsive: true },
      );
      if (!this.temperatureData) { 
        return;
      }
      let temp = this.temperatureData.reduce((stack, current) => {
        return [[...stack[0], current[0]], [...stack[1], current[1]]];
      }, [[], []]);

      // Temperature graph
      let tempPlot = [{
        x: temp[0],
        y: temp[1],
        mode: 'lines',
        line: { color: common.colors[this.index], width: 2, dash: 'dash' },
        name: 'Concrete temperature',
        text: 'Concrete temperature',
        hoverinfo: "x+y+text",
      }];

      // get max
      let maxTempTime = temp[0][temp[0].length -1];

      const maxTemperature = temp[1].reduce((stack, current) => {
        return (current > stack) ? current : stack;
      }, 0);
      if (maxTempTime || maxTemperature) {
        common.calibrationLayout2.xaxis.range = [0, maxTempTime + common.getGraphStep(maxTime)];
        common.calibrationLayout2.yaxis.range = [0, maxTemperature + common.getGraphStep(maxTemperature)];
      }

      Plotly.newPlot(`graph2-${this.index}`,
        tempPlot,
        common.calibrationLayout2,
        { responsive: true }
      );
    }
  },
}

</script>

<style>
.thin-border-corner {
  border: solid 1px #BFB9B4;
  border-radius: 2px;
  box-sizing: border-box;
}

.info-bubble {
  font-size: 1.3em;
  color: grey;
  opacity: 0;
  margin-left: 40px;
}

.info-bubble.active {
  opacity: 1.0;
}

.info-bubble.warning {
  opacity: 1.0;
  color: #EC6608;
}

.info-panel {
  position: absolute;
  z-index: 1;
  top: 40px;
  left: -50px;
  width: 240px;
  /*
  min-height: 100px;
  */
  min-width: 250px;
  width: fit-content;
  height: fit-content;
  border: 1px solid grey;
  border-radius: 2px;
  background-color: white;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.12);
  padding: 8px;
}

.info-panel.warning {
  border: 1px solid #EC6608;

}
</style>

