import { createApp } from 'vue'
import App from './App.vue'
import router from "./router/router";
import Toaster from "@meforma/vue-toaster";
import 'bootstrap/dist/css/bootstrap.css';
import '../src/assets/css/custom_bootstrap.css';
import '../src/assets/css/custom_toaster.css';



import vue3GoogleLogin from 'vue3-google-login';
import Vue3Storage, { StorageType } from "vue3-storage";
// fontawesome for icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faXmark, faPlus, faRotateLeft, faChevronLeft, faChevronRight, faCircleExclamation, faTriangleExclamation, faCircleInfo, faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons'
import { faCopy } from '@fortawesome/free-regular-svg-icons';

library.add(faXmark);
library.add(faPlus);
library.add(faRotateLeft);
library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faCircleExclamation);
library.add(faCircleInfo);
library.add(faTriangleExclamation);
library.add(faLock);
library.add(faLockOpen);
library.add(faCopy);

let gauthClientId = "862254348080-1s99tg1oid419a8skvnisfmmtb2frins.apps.googleusercontent.com";

const app = createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(Toaster)
  .use(router)
  .use(Vue3Storage, { namespace: "smartcast-", storage: StorageType.Local })
  .use(vue3GoogleLogin, {
    clientId: gauthClientId
  });

//dev
/*
app.config.globalProperties.$modelLambdaUrl = "https://zmy45ka7ftxxoesb6ifcohpiye0gjezu.lambda-url.eu-west-1.on.aws/";
app.config.globalProperties.$pdfLambdaUrl = "https://un7nfptd7z44x3cixpyeusdbpa0dslab.lambda-url.eu-west-1.on.aws/";
*/

//prod
/*
*/
app.config.globalProperties.$modelLambdaUrl = "https://gimrqs4xwthzkj33k63avleudy0tqtta.lambda-url.eu-west-1.on.aws/";
app.config.globalProperties.$pdfLambdaUrl = "https://gef3m5qubu7jwonkdsdbrfhpdm0zpwbi.lambda-url.eu-west-1.on.aws/";

app.mount('#app')